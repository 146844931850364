import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",

    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  progress: {
    fontSize: "2rem",
  },
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.border}>
        <CircularProgress size="3rem" />
        <Typography variant="h6">Bitte warten</Typography>
      </div>
    </div>
  );
};

export default Loading;
